header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	margin: auto;
	padding: 2rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

nav {
	text-transform: uppercase;
	font-weight: 600;
}

ul {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 3rem;
	list-style: none;
}

li {
	padding: 0.5rem;
}
.hamburger {
	display: none;
}

@media (max-width: 550px) {
	header {
		padding: 0;
	}
	nav {
		height: 100vh;
		width: 100vh;
		padding-top: 5rem;
		background-color: #1597e5;
		transform: translateX(100vw);
		transition: 0.5s linear;
	}
	nav.open {
		transform: translateX(0);
	}
	ul {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		justify-content: center;
		align-items: center;
		font-size: 1.3rem;
	}
	.logo {
		align-self: start;
		padding: 1rem;
	}
	.hamburger {
		display: block;
		position: fixed;
		top: 2rem;
		right: 1rem;
		font-size: 2rem;
		z-index: 10;
	}
}
