.contact {
	display: flex;
	gap: 8rem;
}

.contact_left {
	flex: 1;
	max-width: 450px;
}

.contact_right {
	flex: 1;
	max-width: 450px;
	margin-top: 1rem;
}

@media (max-width: 960px) {
	.contact {
		flex-direction: column;
	}
}

.contact_txt {
	margin: 2.5rem 0;
}
.contact p {
	line-height: 1.4rem;
	margin: 1rem 0;
}

.socials {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	margin-top: 2rem;
}

.socials a {
	display: flex;
	gap: 1rem;
	align-items: center;
	font-weight: 600;
}

.social_icon {
	font-size: 2.4rem;
}
/* FORM */

form {
	display: flex;
	flex-direction: column;
}

.form-field {
	position: relative;
}

.form-text textarea {
	width: 100%;
	padding: 0.5rem;
	border: solid 1px;
	border-radius: 5px;
	font-family: inherit;
}

.contact input[type='text'] {
	text-decoration: none;
	border: 0;
	background: 0;
	margin: 1rem 0;
	width: 100%;
	outline: none;
	height: 3rem;
	color: inherit;
}

.contact label {
	position: absolute;
	top: 30px;
	left: 0;
}

.form-text {
	margin-top: 58px;
	margin-bottom: 1rem;
}

.form-text label {
	position: absolute;
	top: -30px;
}

.contact label span {
	display: inline-block;
	font-size: 1rem;
	min-width: 5px;
	transition: 0.3s ease-in-out;
}

.contact input:focus + label span,
.contact input:valid + label span {
	transform: translateY(-30px);
}
