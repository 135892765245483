.home h2 {
	width: 70%;
	margin: 0.5rem 0 1.5rem 0;
}

.home p {
	font-size: 1.25rem;
	margin: 0.3rem 0;
}

.home button {
	margin-top: 3rem;
}
@media (min-width: 30em) {
	h1.marion {
		font-size: 12vw;
	}
}
@media (min-width: 40em) {
	h1.marion {
		font-size: 6vw;
	}
}
