.switchThemeBtn {
	position: absolute;
	bottom: 2rem;
	right: 2rem;
	display: flex;
	align-items: center;
	gap: 0.5rem;
	font-size: 0.8rem;
}
.switch {
	position: relative;
	height: 1.8rem;
	width: 3.2rem;
	display: inline-block;
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.switch_slider {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	border-radius: 35px;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	cursor: pointer;
}

.switch_slider:before {
	position: absolute;
	content: '';
	height: 1.4rem;
	width: 1.4rem;
	left: 0.25rem;
	bottom: 0.2rem;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border-radius: 50%;
}

input[type='checkbox']:checked + .switch_slider::before {
	-webkit-transform: translateX(1.3rem);
	-ms-transform: translateX(1.3rem);
	transform: translateX(1.3rem);
}
