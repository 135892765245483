.about_top {
	display: flex;
	margin-bottom: 1.8rem;
}

.about_bottom,
.technologies {
	display: flex;
}

.about_bottom_left {
	flex: 1;
	display: flex;
	align-items: center;
}

.technologies {
	flex: 1;
	margin-left: 2rem;
}
.about ul {
	display: flex;
	flex-direction: column;
	gap: 0;
	justify-content: start;
	align-items: start;
}

.about_right {
	position: relative;
	flex: 1;
	height: 100%;
	transition: all 0.3s ease-out;
}
.about_left {
	flex: 1;
}

.photo_bg {
	position: absolute;
	top: 10px;
	left: -10px;
	width: 100%;
	height: 100%;
	background-color: '#FFFFFF' !important;
	z-index: -1;
}

.about_right:hover {
	-webkit-filter: hue-rotate(325deg);
	-moz-filter: hue-rotate(325deg);
	filter: hue-rotate(325deg);
}

.about img {
	max-width: 100%;
}

.about p {
	line-height: 1.3rem;
}
.about p:not(:first-child) {
	margin-bottom: 2rem;
}
.chevrons {
	display: flex;
	margin-left: 1.5rem;
	animation: chevron1 0.6s infinite ease-in-out alternate;
}

.chevron1 {
	font-size: 4rem;
	opacity: 0.6;
	transform: scale(0.9);
}
.chevron2 {
	font-size: 4.2rem;
	margin-left: -5px;
}
@keyframes chevron1 {
	100% {
		transform: translateX(6px) scale(1);
	}
}

@media (max-width: 960px) {
	.about_top,
	.about_bottom {
		flex-direction: column;
	}
	.about_bottom_left {
		margin: 1rem 0;
	}
}

@media (min-width: 961px) {
	.about_left {
		width: 70%;
		max-width: 775px;
		margin-right: 5%;
	}
	.about img {
		width: 775px;
	}
}
